import { IM, IMStyle, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import Clipboard from '@react-native-clipboard/clipboard'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer'

import { getApi } from '../apis/apiCalls'
import { AccessData } from '../apis/types/apiResponseTypes'
import { accessDataCardStyle } from '../cards/accessData/AccessDataCard'
import CONSTANTS from '../constants/Constants'
import { REQUEST_OTP } from '../constants/Keys'
import { useDataProvider } from '../dataProvider/hooks/useDataProvider'
import useVault from '../hooks/useVault'
import VaultModal from '../modals/VaultModal'
import { ThemeColorExpanded } from '../types'
import { utils } from '../utils/utils'
import useControlledLoader from './Infominds/hooks/useControlledLoader'
import PressableIcon from './Infominds/PressableIcon'
import SkeletonText from './skeleton/SkeletonText'

interface Props {
  accessGranted: boolean
  data: AccessData
}

export default function Otp({ accessGranted, data }: Props) {
  const { i18n } = useLanguage()
  const { sessionId } = useVault()
  const navigation = useNavigation()
  const { isOnline } = useDataProvider()
  const modalController = useModalController()
  const { client } = useDataProvider()
  const { theme } = useTheme<ThemeColorExpanded>()
  const {
    item: response,
    loadItem: getOtp,
    loading,
  } = useControlledLoader(getApi(client).getOpt, {
    id: REQUEST_OTP,
    onResult: result => {
      if (result.vaultState === 'SessionClosed') {
        modalController.show()
        return 'vault-locked'
      } else {
        return 'vault-unlocked'
      }
    },
  })
  const [seconds, setSeconds] = useState(0)
  const [copied, setCopied] = useState(false)

  const otp = response?.data?.otp
  const disableCopy = loading !== false || !accessGranted

  useEffect(() => {
    if (!accessGranted) return

    optRevalidate()
  }, [])

  useEffect(() => {
    copied && setTimeout(() => setCopied(false), CONSTANTS.resetCheckIcon)
  }, [copied])

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (isOnline && loading === 'catched') {
      interval = optRevalidate()
    }

    return () => clearInterval(interval)
  }, [isOnline, loading])

  useEffect(() => {
    if (loading !== false) return

    const newSec = response?.data?.remainingSeconds
    if (newSec === undefined) return

    setSeconds(newSec)
    const timeout = optRevalidate(newSec)

    return () => clearInterval(timeout)
  }, [loading, response])

  const optRevalidate = (revalidationSeconds = 0, id?: string) => {
    return setTimeout(() => {
      getOtp({ VaultSessionId: id ?? sessionId, id: data.id })
    }, revalidationSeconds * 1000)
  }

  const copyToClipboard = () => {
    if (disableCopy || !otp) return

    setCopied(true)
    Clipboard.setString(otp)
  }

  return (
    <>
      <IM.View spacing="top" style={styles.row}>
        <IM.View style={[styles.row, styles.flex, styles.center]}>
          <IM.View spacing="right">
            <Pressable style={styles.flex} disabled={disableCopy} onPress={copyToClipboard}>
              <IM.Text style={accessDataCardStyle.title}>One time password (OTP)</IM.Text>
              {disableCopy ? (
                <IM.Text>*************</IM.Text>
              ) : (
                <>
                  {loading !== false ? (
                    <IM.View style={styles.skeletonContainer}>
                      <SkeletonText width={80} height={16} />
                    </IM.View>
                  ) : (
                    <IM.Text secondary={copied}>{copied ? i18n.t('COPIED_TO_CLIPBOARD') : otp}</IM.Text>
                  )}
                </>
              )}
            </Pressable>
          </IM.View>
          <IM.View spacing="left">
            {!disableCopy && accessGranted && (
              <CountdownCircleTimer
                key={utils.generateUuid()}
                isPlaying
                duration={29}
                initialRemainingTime={seconds}
                onUpdate={remaining => setSeconds(remaining)}
                colors={['#25D07C', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[7, 5, 2, 0]}
                size={24}
                strokeWidth={1}>
                {({ remainingTime }) => <IM.Text style={{ fontSize: IMStyle.typography.fontSizeSmall - 2 }}>{remainingTime}</IM.Text>}
              </CountdownCircleTimer>
            )}
          </IM.View>
        </IM.View>
        {copied ? (
          <PressableIcon icon={['fal', 'check']} size={18} hitSlop={4} color={theme.general.info} opacityColor={theme.card.background} />
        ) : (
          <PressableIcon icon={['fal', 'copy']} size={16} disabled={disableCopy} hitSlop={4} onPress={copyToClipboard} />
        )}
      </IM.View>
      <VaultModal controller={modalController} onAccept={id => optRevalidate(0, id)} />
    </>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
  },
  skeletonContainer: { paddingTop: 3, paddingBottom: 1 },
  center: { alignItems: 'center' },
})
