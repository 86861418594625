import { IM, IMLayout, IMStyle, useLanguage } from '@infominds/react-native-components'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import cloneDeep from 'lodash/cloneDeep'
import React, { createRef, memo, useEffect, useMemo, useState } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { ArticleSparePart, TicketArticle } from '../../apis/types/apiResponseTypes'
import ArticleSparePartCard from '../../cards/activity/ArticleSparePartCard'
import FlashListData from '../../components/FlashListData'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import BaseTextInputTitle from '../../components/input/baseTextInput/BaseTextInputTitle'
import KeyboardAware from '../../components/KeyboardAware'
import NoEntry from '../../components/NoEntry'
import useSearch from '../../components/screen/hooks/useSearch'
import TicketArticleSelector from '../../components/selectors/TicketArticleSelector'
import { TICKET_SPARE_PARTS_DONE_ID } from '../../constants/ButtonIds'
import { REQUEST_TICKET_ARTICLE_SPARE_PARTS } from '../../constants/Keys'
import { FormProvider } from '../../contexts/FormContext'
import {
  COMPATIBLE_ONLY_ID,
  IN_STOCK_ID,
  PRE_CALCULATION_ID,
  SparePartsFilterType,
  SparePartsOrderType,
} from '../../contexts/SparePartsFilterContext'
import useSparePartsFilter from '../../hooks/useSparePartsFilter'
import { SparePartDirection } from '../../types'
import { invoiceTypeAtom, stockQuantityAtom, ticketArticleAtom } from '../../utils/stateManager'

interface Props {
  ticketId: string
  activityId: string
  direction: SparePartDirection
}

const ActivitySparePartsView = ({ ticketId, direction, activityId }: Props) => {
  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { filters, orders } = useSparePartsFilter()

  const articles = useRecoilValue(ticketArticleAtom)
  const invoices = useRecoilValue(invoiceTypeAtom)
  const resetCommonStockQuantity = useResetRecoilState(stockQuantityAtom)

  const [ticketArticle, setTicketArticle] = useState<TicketArticle | undefined>(undefined)

  const listRef = createRef<FlashList<ArticleSparePart>>()

  const {
    item: spareParts,
    loadItem: getSpareParts,
    loading,
  } = useControlledLoader(api.getArticleSpareParts, { id: REQUEST_TICKET_ARTICLE_SPARE_PARTS })

  useEffect(() => {
    resetCommonStockQuantity()
  }, [])

  useEffect(() => {
    refresh(search)
  }, [filters, ticketArticle, search])

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [filters, orders, ticketArticle])

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: false,
      offset: 0,
    })
  }, [search])

  const refresh = (searchText: string | undefined) => {
    if (direction === 'Removed' && ticketArticle === undefined) return

    let id: string | undefined
    let compatibleOnly = false
    let preCalculation = false
    let inStock = false

    filters.forEach(filter => {
      if (filter.id === SparePartsFilterType.Depots) {
        const found = filter.elements.find(depot => depot.active)

        if (found) {
          id = found.id
        }
      }

      if (filter.id === SparePartsFilterType.Stock) {
        filter.elements.forEach(el => {
          if (el.id === IN_STOCK_ID) {
            inStock = el.active
          }
        })
      }

      if (filter.id === SparePartsFilterType.Compatible) {
        filter.elements.forEach(el => {
          if (el.id === COMPATIBLE_ONLY_ID) {
            compatibleOnly = el.active
          }

          if (el.id === PRE_CALCULATION_ID) {
            preCalculation = el.active
          }
        })
      }
    })

    getSpareParts({
      direction: direction,
      depositId: direction === 'Installation' ? id : undefined,
      serialnumberIdParent: direction === 'Removed' ? ticketArticle?.serialnumberId : undefined,
      ticketId: compatibleOnly || preCalculation ? ticketId : undefined,
      inStock: inStock ? true : undefined,
      preCalculations: preCalculation ? true : undefined,
      searchText,
    })
  }

  const renderItem = (elem: ListRenderItemInfo<string | ArticleSparePart>) => {
    const isFirst = elem.index === 0
    const item = elem.item

    if (typeof item === 'string') {
      return (
        <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginTop: (isFirst ? 1 : 2) * IMLayout.horizontalMargin }}>
          <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{item}</IM.Text>
        </IM.View>
      )
    } else {
      return (
        <ArticleSparePartCard
          activityId={activityId}
          sparePart={item}
          invoiceTypes={invoices ?? []}
          ticketArticles={articles ?? []}
          direction={direction}
          style={{
            marginTop: isFirst ? (direction === 'Installation' ? IMLayout.horizontalMargin : -4) : IMLayout.horizontalMargin,
            marginHorizontal: 2 * IMLayout.horizontalMargin,
          }}
        />
      )
    }
  }

  const filteredSpareParts: ArticleSparePart[] | undefined = useMemo(() => {
    let sparePartsClone = cloneDeep(spareParts) ?? []

    if (direction === 'Installation') {
      const activeOrder = orders.find(order => order.active)
      switch (activeOrder?.data.id) {
        case SparePartsOrderType.ArticleCode: {
          sparePartsClone.sort((a, b) => a.articleCode.localeCompare(b.articleCode))
          break
        }
        case SparePartsOrderType.ArticleName: {
          sparePartsClone.sort((a, b) => a.articleSearchtext.localeCompare(b.articleSearchtext))
          break
        }
        case SparePartsOrderType.SerialNumberAscending: {
          const withSn = sparePartsClone.filter(el => el.serialnumber !== undefined)
          const withOutSn = sparePartsClone.filter(el => el.serialnumber === undefined)

          withSn.sort((a, b) => (a.serialnumber && b.serialnumber ? a.serialnumber.localeCompare(b.serialnumber) : 0))
          sparePartsClone = [...withSn, ...withOutSn]
          break
        }
        case SparePartsOrderType.SerialNumberDescending: {
          const withSn = sparePartsClone.filter(el => el.serialnumber !== undefined)
          const withOutSn = sparePartsClone.filter(el => el.serialnumber === undefined)

          withSn.sort((a, b) => (a.serialnumber && b.serialnumber ? b.serialnumber.localeCompare(a.serialnumber) : 0))
          sparePartsClone = [...withSn, ...withOutSn]
          break
        }
      }
    }

    if (direction === 'Installation') {
      sparePartsClone.unshift({
        articleBarcode: '',
        articleModel: '',
        articleCode: '',
        articleId: '',
        articleSearchtext: '',
        depositId: '',
        id: '',
        isLottoSerialnumberActive: false,
        isSerialnumberActive: false,
        isWarehouseMovementActive: true,
        ticketId: '',
        serialnumber: '',
        isDummy: true,
      })
    }

    return sparePartsClone
  }, [filters, orders, spareParts])

  return (
    <FormProvider>
      {direction === 'Removed' && (
        <IM.View
          style={{
            marginHorizontal: 1.8 * IMLayout.horizontalMargin,
            marginTop: 1.8 * IMLayout.horizontalMargin,
          }}>
          <TicketArticleSelector
            value={ticketArticle ?? articles?.at(0)}
            onChange={setTicketArticle}
            articles={articles}
            loading={articles !== undefined ? false : 'reloading'}
          />
          <IM.View spacing="top">
            <BaseTextInputTitle title={i18n.t('SPARE_PARTS')} />
          </IM.View>
        </IM.View>
      )}
      {direction === 'Installation' || (direction === 'Removed' && ticketArticle !== undefined) ? (
        <KeyboardAware>
          <FlashListData
            ref={listRef}
            data={loading === 'reloading' ? [] : filteredSpareParts}
            loading={loading !== false || articles === undefined || invoices === undefined ? 'reloading' : false}
            renderItem={renderItem}
            noDataMessage={i18n.t('NO_SPARE_PARTS_FOUND')}
            refresh={() => refresh(search)}
            id={TICKET_SPARE_PARTS_DONE_ID}
            keyExtractor={item => item.articleId + (item.serialnumberId ?? '') + item.depositId + (item.isDummy ? 'dummy' : '')}
            estimatedItemSize={200}
            extraData={articles}
            spacingSkeleton={direction === 'Installation' ? undefined : ['bottom', 'horizontal']}
          />
        </KeyboardAware>
      ) : (
        <IM.View spacing={['horizontal', 'top']} style={IMLayout.flex.f1}>
          <NoEntry description={i18n.t('SELECT_SERIAL_NUMBER_SPARE_PARTS')} />
        </IM.View>
      )}
    </FormProvider>
  )
}

export default memo(ActivitySparePartsView)
