import { Storage, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import api from '../apis/apiCalls'
import useControlledLoader from '../components/Infominds/hooks/useControlledLoader'
import {
  REQUEST_SPARE_PART_DEPOTS,
  STORAGE_KEY_FILTER_CONTEXT_SPARE_PARTS_FILTER,
  STORAGE_KEY_FILTER_CONTEXT_SPARE_PARTS_ORDER,
} from '../constants/Keys'
import useUserSettings from '../hooks/useUserSettings'
import { Filter, FilterElement, FilterStorageType, Order, StorageType } from '../types'
import ticketUtils from '../utils/TicketUtils'

export enum SparePartsOrderType {
  ArticleName = 'ArticleNameKey',
  ArticleCode = 'ArticleCodeKey',
  SerialNumberDescending = 'SerialNumberDescendingKey',
  SerialNumberAscending = 'SerialNumberAscendingKey',
}

export enum SparePartsFilterType {
  Depots = 'DepotsKey',
  Compatible = 'CompatibleKey',
  Stock = 'StockKey',
}

interface ContextProps {
  filters: Filter<SparePartsFilterType>[]
  orders: Order<SparePartsOrderType>[]
  setFilters: (filters: Filter<SparePartsFilterType>[]) => void
  setOrders: (orders: Order<SparePartsOrderType>[]) => void
  changeFilterStatus: (id: SparePartsOrderType | SparePartsFilterType, filterElemId?: string) => void
}

const SparePartsFilterContext = createContext<ContextProps | undefined>(undefined)

interface ProviderProps extends PropsWithChildren {
  storageKeyUniqueId: string
  disableCompatibilityFilter?: boolean
  disableStockFilter?: boolean
  disableDepositFilter?: boolean
  onEnableFilter: (value: boolean) => void
}

export const IN_STOCK_ID = 'IN_STOCK_ID'
export const COMPATIBLE_ONLY_ID = 'COMPATIBLE_ONLY_ID'
export const PRE_CALCULATION_ID = 'PRE_CALCULATION_ID'

export const SparePartsFilterProvider = ({
  storageKeyUniqueId,
  disableCompatibilityFilter,
  disableStockFilter,
  disableDepositFilter,
  children,
  onEnableFilter,
}: ProviderProps) => {
  const { i18n, language } = useLanguage()
  const { userSettings } = useUserSettings()
  const [orders, setOrders] = useState<Order<SparePartsOrderType>[]>(
    [
      {
        active: false,
        data: { id: SparePartsOrderType.ArticleName, name: ticketUtils.getNameByType(SparePartsOrderType.ArticleName, i18n) },
      },
      {
        active: false,
        data: { id: SparePartsOrderType.ArticleCode, name: ticketUtils.getNameByType(SparePartsOrderType.ArticleCode, i18n) },
      },
      {
        active: false,
        data: {
          id: SparePartsOrderType.SerialNumberDescending,
          name: ticketUtils.getNameByType(SparePartsOrderType.SerialNumberDescending, i18n),
          icon: ['fal', 'arrow-up-1-9'],
        },
      },
      {
        active: false,
        data: {
          id: SparePartsOrderType.SerialNumberAscending,
          name: ticketUtils.getNameByType(SparePartsOrderType.SerialNumberAscending, i18n),
          icon: ['fal', 'arrow-down-1-9'],
        },
      },
    ].sort((a, b) => a.data.name.localeCompare(b.data.name)) as Order<SparePartsOrderType>[]
  )

  const filterInit = useMemo<Filter<SparePartsFilterType>[]>(
    () => [
      ...(disableCompatibilityFilter
        ? []
        : [
            {
              id: SparePartsFilterType.Compatible,
              name: ticketUtils.getNameByType(SparePartsFilterType.Compatible, i18n),
              elements: [
                { id: COMPATIBLE_ONLY_ID, description: i18n.t('COMPATIBLE_ONLY'), active: true },
                { id: PRE_CALCULATION_ID, description: i18n.t('PRE_CALCULATION'), active: false },
              ],
            },
          ]),
      ...(disableStockFilter
        ? []
        : [
            {
              id: SparePartsFilterType.Stock,
              name: ticketUtils.getNameByType(SparePartsFilterType.Stock, i18n),
              elements: [
                { id: IN_STOCK_ID, description: i18n.t('IN_STOCK'), active: true },
                // { id: OUT_OF_STOCK_ID, description: i18n.t('OUT_OF_STOCK'), active: false },
              ],
            },
          ]),
    ],
    [disableCompatibilityFilter, disableStockFilter]
  )
  const [filters, setFilters] = useState<Filter<SparePartsFilterType>[]>(filterInit)

  const ordersStorage = Storage<StorageType[]>(STORAGE_KEY_FILTER_CONTEXT_SPARE_PARTS_ORDER + storageKeyUniqueId)
  const filtersStorage = Storage<FilterStorageType<SparePartsFilterType>[]>(STORAGE_KEY_FILTER_CONTEXT_SPARE_PARTS_FILTER + storageKeyUniqueId)

  const {
    item: depots,
    loadItem: getDepots,
    loading: loadingDepots,
  } = useControlledLoader(api.getSparePartDepot, { id: REQUEST_SPARE_PART_DEPOTS + storageKeyUniqueId })

  useEffect(() => {
    if (!disableDepositFilter) getDepots()

    ordersStorage
      .load()
      .then(loaded => {
        if (loaded !== null) {
          const clone = cloneDeep(orders).map(elem => {
            const found = loaded.find(ld => ld.id === elem.data.id)

            if (found) {
              return { ...elem, active: found.value }
            } else {
              return elem
            }
          })

          setOrders(clone)
        }
      })
      .catch(err => console.error('Failed loading ticket sort by', err))
  }, [disableDepositFilter])

  useEffect(() => {
    setOrders(prv =>
      prv.map(el => {
        return { ...el, data: { ...el.data, name: ticketUtils.getNameByType(el.data.id, i18n) } }
      })
    )
  }, [language])

  useEffect(() => {
    if (disableDepositFilter) {
      initFilters(null)
      return
    }
    if (loadingDepots === false && depots && depots.length !== 0) {
      const gotDepots: FilterElement[] = []

      depots?.forEach(depot => {
        if (depot.inactive === false) {
          gotDepots.push({ id: depot.id, active: depot.id === userSettings?.depositId, description: `${depot.code} - ${depot.description}` })
        }
      })

      initFilters({
        id: SparePartsFilterType.Depots,
        name: ticketUtils.getNameByType(SparePartsFilterType.Depots, i18n),
        elements: gotDepots.sort((a, b) => (a.description && b.description ? a.description.localeCompare(b.description) : 0)),
      })
    } else {
      onEnableFilter(false)
    }
  }, [loadingDepots, depots, userSettings, filterInit])

  const initFilters = useCallback(
    (gotDepots: Filter<SparePartsFilterType> | null) => {
      const filterClone = gotDepots ? cloneDeep([gotDepots, ...filterInit]) : cloneDeep(filterInit)

      filtersStorage
        .load()
        .then(loaded => {
          if (loaded !== null) {
            const loadedFilters: Filter<SparePartsFilterType>[] = []
            loaded.forEach(el => {
              const elements: FilterElement[] = []
              el.data.forEach(data => {
                elements.push({ id: data.id, active: data.value })
              })

              loadedFilters.push({ id: el.id, name: ticketUtils.getNameByType(el.id, i18n), elements })
            })

            loadedFilters.forEach(loadedFilter => {
              if (loadedFilter.id === SparePartsFilterType.Depots) {
                ticketUtils.updateSparePartFilters(loadedFilter, filterClone)
              } else if (loadedFilter.id === SparePartsFilterType.Compatible) {
                ticketUtils.updateSparePartFilters(loadedFilter, filterClone)
              } else if (loadedFilter.id === SparePartsFilterType.Stock) {
                ticketUtils.updateSparePartFilters(loadedFilter, filterClone)
              }
            })
          }

          setFilters(filterClone)
          onEnableFilter(true)
        })
        .catch(err => console.error('Failed loading ticket filter', err))
    },
    [filterInit]
  )

  const changeFilterStatus = useCallback(
    (id: SparePartsOrderType | SparePartsFilterType, filterElemId?: string) => {
      if (Object.values(SparePartsOrderType).includes(id as SparePartsOrderType)) {
        const clone = cloneDeep(orders)

        clone.forEach(filter => {
          if (filter.data.id === id) {
            filter.active = !filter.active
          }
        })

        handleOrderUpdate(clone)
      } else {
        const clone = cloneDeep(filters)

        clone.forEach(filter => {
          if (filter.id === id) {
            filter.elements.forEach(el => {
              if (el.id === filterElemId) {
                el.active = !el.active
              }
            })
          }
        })

        handleFilterUpdate(clone)
      }
    },
    [filters, orders]
  )

  const handleOrderUpdate = (newOrders: Order<SparePartsOrderType>[]) => {
    const toSave: StorageType[] = []
    newOrders.forEach(order => toSave.push({ id: order.data.id, value: order.active }))
    ordersStorage.save(toSave).catch(err => console.error('Failed saving ticket sort by', err))
    setOrders(newOrders)
  }

  const handleFilterUpdate = (newFilters: Filter<SparePartsFilterType>[]) => {
    const toSave: FilterStorageType<SparePartsFilterType>[] = []
    newFilters.forEach(filter => {
      const data: StorageType[] = []

      filter.elements.forEach(el => {
        data.push({ id: el.id, value: el.active })
      })

      toSave.push({ id: filter.id, data })
    })
    filtersStorage.save(toSave).catch(err => console.error('Failed saving ticket filter', err))
    setFilters(newFilters)
  }

  const props = useMemo<ContextProps>(
    () => ({
      filters,
      orders,
      setFilters: handleFilterUpdate,
      setOrders: handleOrderUpdate,
      changeFilterStatus,
    }),
    [filters, orders]
  )

  return <SparePartsFilterContext.Provider value={props}>{children}</SparePartsFilterContext.Provider>
}

export default SparePartsFilterContext
