import { Utils, type AlertContextProps, type Language } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Route } from '@react-navigation/native'
import Color from 'color'
import { i18n } from 'i18next'
import groupBy from 'lodash/groupBy'
import { ColorSchemeName, Linking, NativeScrollEvent, NativeSyntheticEvent } from 'react-native'
import { AtomEffect, DefaultValue, WrappedValue } from 'recoil'
import { serializeError } from 'serialize-error'

import { AccessData, InvoiceType } from '../apis/types/apiResponseTypes'
import CONSTANTS from '../constants/Constants'
import {
  REFRESH_ADD_FIELD_ACTIVITY_KEY,
  REFRESH_ADD_FIELD_TICKET_KEY,
  REFRESH_CLASSIFICATION_SERIAL_NUMBER_KEY,
  REFRESH_CLASSIFICATION_TICKET_KEY,
} from '../constants/EmitterKeys'
import {
  AdditionalField,
  AdditionalFieldType,
  ApiError,
  ClassificationType,
  CustomerLanguage,
  FieldType,
  FilterInfo,
  InfiniteLoadingType,
  ListSection,
  ThemeColorExpanded,
} from '../types'
import { numberUtils } from './numberUtils'
import TimeUtils from './TimeUtils'

type AbortError = {
  name: string
}

type setSelfType<T> =
  | T
  | DefaultValue
  | Promise<T | DefaultValue>
  | WrappedValue<T>
  | ((param: T | DefaultValue) => T | DefaultValue | WrappedValue<T>)

const ISO_8601_REGEX =
  /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])\.[0-9]{3}Z$/

const appUtils = {
  customerLanguageToCountryCode(language: CustomerLanguage) {
    switch (language) {
      case 'English':
        return 'en'
      case 'Italian':
        return 'it'
      case 'German':
        return 'de'
      case 'Undefined':
      default:
        return undefined
    }
  },
  capitalizeFirstLetter(str: string) {
    if (str[0]) return str[0].toUpperCase() + str.slice(1)

    return str
  },
  localStorageEffect<T>(key: string): AtomEffect<T> {
    return ({ setSelf, onSet }) => {
      AsyncStorage.getItem(key)
        .then(savedValue => {
          if (savedValue !== null) {
            setSelf(JSON.parse(savedValue) as setSelfType<T>)
          }
        })
        .catch(err => console.error(`Failed fetching ${key}:`, err))

      onSet((newValue, _, isReset) => {
        if (isReset) {
          AsyncStorage.removeItem(key).catch(err => console.error(`Failed resetting ${key}:`, err))
        } else {
          AsyncStorage.setItem(key, JSON.stringify(newValue)).catch(err => console.error(`Failed saving ${key}:`, err))
        }
      })
    }
  },
  insert<T>(arr: T[], index: number, newItem: T) {
    return [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index),
    ]
  },
  isAbortError(err: AbortError | unknown): err is AbortError {
    return (err as AbortError).name !== undefined && (err as AbortError).name === 'AbortError'
  },
  calculateInitials(inputString: string) {
    if (!inputString) return ''
    const simplifiedString = inputString.replace(/[^a-zA-Z0-9]/g, '')
    let initial = ''

    if (simplifiedString.length === 0) {
      initial = Math.random().toString(36).slice(2).substring(2, 4).toUpperCase()
    } else if (simplifiedString.length === 1) {
      initial = simplifiedString[0] + Math.random().toString(36).slice(2).substring(2, 3).toUpperCase()
    } else {
      initial = `${simplifiedString[0]}${simplifiedString[1]}`
    }

    return initial
  },
  filter: <T>(
    data: T[],
    value: string,
    keys: (
      | keyof T
      | {
          /** Used to search for a string that is a composition of different object keys value */
          searchStringCompositor: (object: T) => string
        }
    )[],
    language?: Language
  ) => {
    if (value === '') {
      return data
    }

    const lowerCaseSearchValue = value.toLowerCase()

    return data.filter(elem => {
      let found = false

      keys.forEach(key => {
        if (typeof key !== 'object') {
          const objectElem = elem[key]

          if (typeof objectElem === 'string' && found === false) {
            if (language && objectElem.match(ISO_8601_REGEX)) {
              found = TimeUtils.format(objectElem, language).includes(lowerCaseSearchValue)
            } else {
              found = objectElem.toLowerCase().includes(lowerCaseSearchValue)
            }
          }
        } else {
          const stringComposed = key.searchStringCompositor(elem)
          found = stringComposed.toLowerCase().includes(lowerCaseSearchValue)
        }
      })

      return found
    })
  },
  group: <T>(data: T[], groupByKey: keyof T, noGroupPlaceholder?: string) => {
    const toRet: ListSection<T>[] = []
    const lastElem: ListSection<T>[] = []

    const groups = groupBy(data, groupByKey)

    for (const key of Object.keys(groups)) {
      if (key === 'undefined' && noGroupPlaceholder) {
        lastElem.push({ title: noGroupPlaceholder, data: groups[key] })
      } else {
        toRet.push({ title: key, data: groups[key] })
      }
    }

    toRet.sort((a, b) => {
      if (!a.title || !b.title) return 0

      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1

      return 0
    })

    return [...toRet, ...lastElem]
  },
  arrayMove<T>(arr: (T | undefined)[], oldIndex: number, newIndex: number) {
    while (oldIndex < 0) {
      oldIndex += arr.length
    }

    while (newIndex < 0) {
      newIndex += arr.length
    }

    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  },
  getAccessDataGroups: (data: AccessData[]) => {
    const group: FilterInfo[] = []

    data.forEach(elem => {
      if (elem.accessdatagroupId && elem.accessdatagroup && group.find(returnElem => returnElem.id === elem.accessdatagroupId) === undefined) {
        group.push({ id: elem.accessdatagroupId, name: elem.accessdatagroup })
      }
    })

    return group
  },
  openUrl: (url: string, alert: AlertContextProps, errorMessage: string) => {
    if (!url.includes('http://') && !url.includes('https://')) {
      url = 'https://' + url
    }

    Linking.openURL(url).catch(() => alert.alert(Utils.stringValueReplacer(errorMessage, url)))
  },
  openEmail: (alert: AlertContextProps, errorMessage: string, address?: string, body?: string, subject?: string) => {
    if (address === undefined && body === undefined && subject === undefined) throw new Error('body or email must be defined')

    address = address?.replaceAll('mailto:', '')
    address = 'mailto:' + (address ?? '') + '?'

    if (body) address += '&body=' + encodeURIComponent(body)
    if (subject) address += '&subject=' + encodeURIComponent(subject)

    address &&
      Linking.openURL(address).catch(() => {
        return alert.alert(Utils.stringValueReplacer(errorMessage, address))
      })
  },
  openPhone: (number: string, alert: AlertContextProps, translator: i18n) => {
    const original = number

    if (/\d/.test(number)) {
      if (!number.includes('tel:')) {
        number = 'tel:' + number
      }

      number = number.replaceAll(' ', '')

      Linking.openURL(number).catch(() => alert.alert(translator.t('OPEN_PHONE_ERROR_NO_APP_FOUND')))
    } else {
      alert.alert(Utils.stringValueReplacer(translator.t('OPEN_PHONE_ERROR_FORMAT_NOT_VALID'), original))
    }
  },
  formatFileNumber(translator: i18n, elements: number) {
    if (!elements) {
      return translator.t('NO_DOCUMENT')
    } else if (elements === 1) {
      return `${elements} ${translator.t('DOCUMENT_LOWER_CASE')}`
    } else {
      return `${elements} ${translator.t('DOCUMENTS_LOWER_CASE')}`
    }
  },
  infoboxAlert(alert: AlertContextProps, translator: i18n, onDiscard: () => void) {
    alert.alert(translator.t('UNSAVED_CHANGES_TITLE'), translator.t('DISCARD_UNSAVED_CHANGES'), [
      {
        text: translator.t('DISCARD'),
        onPress: onDiscard,
        style: 'destructive',
      },
      {
        text: translator.t('CANCEL'),
        style: 'cancel',
      },
    ])
  },
  // @Philipp: This does not format the date based on the language. However, I don't see performance issue using date-fns on ticket list
  // formatDateString(date: string) {
  //   if (!date) return null
  //   const [parsedDate, _rest] = date.split('T')
  //   const [year, month, day] = parsedDate.split('-')
  //   return `${day}-${month}-${year}`
  // },
  closeToEndDetector(e: NativeSyntheticEvent<NativeScrollEvent>, threshold: number, allDataLoaded: boolean) {
    if (allDataLoaded) {
      let paddingToBottom = threshold
      paddingToBottom += e.nativeEvent.layoutMeasurement.height

      const reference = e.nativeEvent.contentSize.height - paddingToBottom

      if (reference <= 0) return

      if (e.nativeEvent.contentOffset.y >= reference) {
        return true
      }
    }

    return false
  },
  sortAlphabetically<T>(obj: T[], key: keyof T, type: 'asc' | 'desc', sameValueSorter?: (a: T, b: T) => number) {
    let toRet: T[] = []

    try {
      const undefValue = obj.filter(el => el[key] === undefined)
      const withValue = obj.filter(el => el[key] !== undefined)

      withValue.sort((a, b) => {
        const aValue = a[key]
        const bValue = b[key]

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          if (type === 'asc') {
            return aValue.localeCompare(bValue) || (sameValueSorter ? sameValueSorter(a, b) : 0)
          } else {
            return bValue.localeCompare(aValue) || (sameValueSorter ? sameValueSorter(a, b) : 0)
          }
        }
        return 0
      })

      if (type === 'asc') {
        toRet = [...undefValue, ...withValue]
      } else {
        toRet = [...withValue, ...undefValue]
      }
    } catch (err) {
      console.error('Error on sortAlphabetically', err)
    }

    return toRet
  },
  getDocumentTitle: (route: Route<string> | undefined, translator: i18n) => {
    const appName = 'RX+ Service'

    if (route?.name.includes('Ticket')) {
      return translator.t('TAB_TICKETS') + ' | ' + appName
    } else if (route?.name.includes('Password') || route?.name.includes('AccessData')) {
      return translator.t('PASSWORD') + ' | ' + appName
    } else if (route?.name.includes('TicketDetail')) {
      return translator.t('TICKET') + ' | ' + appName
    } else if (route?.name.includes('Customer')) {
      return translator.t('CUSTOMER') + ' | ' + appName
    } else if (route?.name.includes('Infobox')) {
      return translator.t('INFOBOX') + ' | ' + appName
    } else if (route?.name.includes('Settings')) {
      return translator.t('TAB_SETTINGS') + ' | ' + appName
    } else if (route?.name.includes('Sync')) {
      return translator.t('TAB_SYNCHRONIZATION') + ' | ' + appName
    } else if (route?.name.includes('History')) {
      return translator.t('TAB_HISTORY') + ' | ' + appName
    } else if (route?.name.includes('Spare')) {
      return translator.t('TAB_SPAREPARTS') + ' | ' + appName
    } else if (route?.name.includes('Planning')) {
      return translator.t('TAB_PLANNING') + ' | ' + appName
    } else if (route?.name.includes('Activity')) {
      return translator.t('RX_ACTIVITY') + ' | ' + appName
    } else if (route?.name.includes('Quality')) {
      return translator.t('QUANTITY') + ' | ' + appName
    } else {
      return appName
    }
  },
  mergeLoading: (loadings: InfiniteLoadingType[]): InfiniteLoadingType => {
    if (loadings.find(el => el === 'catched') !== undefined) return 'catched'
    if (loadings.find(el => el === 'aborted') !== undefined) return 'aborted'
    if (loadings.find(el => el === 'loadMore') !== undefined) return 'loadMore'
    if (loadings.find(el => el === 'reloading') !== undefined) return 'reloading'
    if (loadings.find(el => el === 'init') !== undefined) return 'init'

    return loadings[0]
  },
  getAdditionFieldValue: (field: AdditionalField, language: Language, translator: i18n) => {
    switch (field.definitionFeldType) {
      case 'Text':
      case 'TextHTML':
      case 'TextRTF':
        return field.stringValue
      case 'Selection':
        return field.description
      case 'Numeric':
        return numberUtils.convertNumberToLocaleString(field.numberValue, language)
      case 'Boolean':
        return field.logicValue === undefined ? undefined : field.logicValue ? translator.t('YES') : translator.t('NO')
      case 'Date':
        return field.dateValue ? TimeUtils.format(field.dateValue, language) : undefined
      case 'ForeignKey':
      case 'None':
        // Not managed
        return undefined
    }
  },
  createAdditionFieldValue: (type: FieldType, value: string | undefined, locale: Language) => {
    const toRet: Pick<AdditionalField, 'stringValue' | 'description' | 'numberValue' | 'logicValue' | 'dateValue'> = {
      stringValue: undefined,
      numberValue: undefined,
      logicValue: undefined,
      dateValue: undefined,
    }

    if (value) {
      switch (type) {
        case 'Text':
        case 'TextHTML':
        case 'TextRTF':
        case 'Selection':
          toRet.stringValue = value
          break
        case 'Numeric': {
          const floatValue = numberUtils.convertLocaleStringToNumber(value, locale)

          toRet.numberValue = floatValue
          break
        }
        case 'Boolean':
          toRet.logicValue = value === 'true' ? true : false
          break
        case 'Date':
          toRet.dateValue = new Date(value).toISOString()
          break
        case 'ForeignKey':
        case 'None':
          break
      }
    }

    return toRet
  },
  processAdditionFieldDtoValue(field: AdditionalField, language: Language) {
    switch (field.definitionFeldType) {
      case 'Text':
      case 'TextHTML':
      case 'TextRTF':
      case 'Selection':
        return field.stringValue
      case 'Numeric':
        return numberUtils.convertNumberToLocaleString(field.numberValue, language)
      case 'Boolean':
        return field.logicValue ? 'true' : 'false'
      case 'Date':
        return field.dateValue
      case 'ForeignKey':
      case 'None':
        return undefined
    }
  },
  addFieldChangeEvent(type: AdditionalFieldType) {
    switch (type) {
      case 'Ticket':
        return REFRESH_ADD_FIELD_TICKET_KEY
      case 'Activity':
        return REFRESH_ADD_FIELD_ACTIVITY_KEY
      case 'None':
      case 'Customer':
      case 'Supplier':
      case 'Article':
      case 'Employee':
      case 'ShippingAddress':
      case 'CorrespondenceAddress':
      case 'Serialnumber':
      case 'Contact':
      case 'Expenses':
        return 'NOT MANAGED'
    }
  },
  classificationChangeEvent(type: ClassificationType) {
    switch (type) {
      case 'Ticket':
        return REFRESH_CLASSIFICATION_TICKET_KEY
      case 'Serialnumber':
        return REFRESH_CLASSIFICATION_SERIAL_NUMBER_KEY
      case 'None':
      case 'Customer':
      case 'Supplier':
      case 'Article':
      case 'Employee':
      case 'Contact':
      case 'Expenses':
      case 'CorrespondezAddress':
      case 'InvestmentGoods':
      case 'Project':
      case 'MaterialProcessing':
      case 'ProcessingType':
      case 'Agent':
      case 'Resource':
      case 'Campaigns':
      case 'Complaint':
      case 'Knowlegdebase':
      case 'ServicemanagementContract':
      case 'PrecalculationArticle':
      case 'Losses':
      case 'Qualitycontrol':
      case 'Promotion':
      case 'BonusContract':
      case 'Address':
      case 'SalesOpportunity':
      case 'BlanketOrder':
      case 'MaterialBlocking':
      case 'AccountingKeyPresencetime':
        return 'NOT MANAGED'
    }
  },
  isBackendError(obj: unknown): obj is ApiError {
    return (obj as ApiError).Message !== undefined
  },
  getBackendErrorMessage(obj: unknown) {
    const error = serializeError(obj)
    return appUtils.isBackendError(error) ? error.Message : JSON.stringify(error)
  },
  getOpacity(colorScheme: NonNullable<ColorSchemeName>) {
    return colorScheme === 'light' ? CONSTANTS.disabledOpacityLight : CONSTANTS.disabledOpacityDark
  },
  getModalOpacity(colorScheme: NonNullable<ColorSchemeName>) {
    return colorScheme === 'light' ? 0.45 : 0.65
  },
  getModalBackground(colorScheme: NonNullable<ColorSchemeName>, theme: ThemeColorExpanded) {
    return colorScheme === 'light' ? theme.background : Color(theme.header.detail.background).darken(0.3).toString()
  },
  getCardButtonColor(active: boolean | undefined, theme: ThemeColorExpanded) {
    return {
      backGroundColor: active ? theme.card.button.active : theme.card.button.inactive,
      fontColor: active ? theme.card.button.icon.active : theme.card.button.icon.inactive,
    }
  },
  formatPrice(value: number, language: Language) {
    return value.toLocaleString(language, { minimumFractionDigits: 2 })
  },
}

export default appUtils
